import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { CartService } from 'src/app/Lazy-loaded-Modules/Cart-module/cart-page/cart-page.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { config } from 'src/configs/config';
import { CartNewService } from '../../Lazy-loaded-Modules/Cart-module/cart-new/cart-new.service';
import { NewNavbarService } from 'src/app/Components/new-navbar/new-navbar.service';
import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';
import { ProductFilterService } from 'src/app/Lazy-loaded-Modules/Product-Modules/product-lists/product-filter/product-filter.service';

@Component({
  selector: 'app-new-navbar',
  templateUrl: './new-navbar.component.html'
})
export class NewNavbarComponent implements OnInit {

  CONFIG = config;
  cartData: any = [];
  allCategories: any;
  categoryImage1: any;
  categoryImage2: any;
  categoryName1: any;
  categoryName2: any;
  subcategory1: any;
  subcategory2: any;
  subcategory3: any;
  subcategory4: any;
  @Input() category: any;
  active = 'dontcheck';
  active1 = '';
  active2 = '';
  deeracolor = [];
  deeradimension = [];
  dropdownOpen = false;
  submenuOpen = false;
  subSubmenuOpen = false;
  constructor(public appService: AppService, private cartService: CartService, private newNavbarService: NewNavbarService, private datePipe: DatePipe, public router: Router, private route: ActivatedRoute, private cartNewService: CartNewService, private localService: LocalStorageService, private productFilterService: ProductFilterService) { }

  ngOnInit(): void {


    if (this.cartNewService.getCartItems() !== null) {
      this.cartData = this.cartNewService.getCartItems();

      if (this.router.url.includes('/confirmation')) {

        //this.cartData.splice(0,this.cartData.length)

        this.removeallcartItem(this.cartData.length)


      }
    }
    this.cartNewService.currentData.subscribe(
      currentData => {
        let curData: any = currentData;
        if (curData.type === "cartData") {
          this.cartData = curData.value;
        }
      }
    )

    this.newNavbarService.currentData.subscribe(
      currentData => {
        let curData: any = currentData;
        if (curData.name === "categoryData") {
          console.log("inside................")
          this.getAllCategories();
        }
      }
    )

    this.getAllCategories();

    this.productFilterService.updateAdminFormEvent({
      name: "categoryName",
      value: "disposable cutlery"

    })
  }

  removeItem(index) {
    this.appService.cartProducts.results.splice(index, 1);
    this.cartService.updateCartItem(this.appService.cartProducts.results);
  }


  appliedDeeraColor(data) {
    this.active2 = "";
    this.deeracolor = [];
    this.active2 = data;
    if (config.title === 'Deera') {
      console.log("facettttttttttt" + data)
      this.deeracolor.push(data)
      // this.callListingScreen()
    }
  }

  appliedDeeraColor1(data) {
    // this.deeracolor=[];
  }

  appliedDeeraDimension(data1) {
    this.deeradimension = [];
    if (config.title === 'Deera') {
      console.log("facettttttttttt" + data1)
      this.deeradimension.push(data1)
      // this.callListingScreen()
      // this.productListing()
    }
  }


  goToCheckout() {

    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }

    this.appService.postApiCall(requestData, ENDPOINTS.ACCEPTANCE_TIMINGS).subscribe(
      resp => {
        if (resp.success === 1 && resp.result.data.length > 0) {

          var today = moment();

          var acceptanceTiming = resp.result.data[0].order_acceptance_timing;

          var timeAvailable: boolean;

          if (acceptanceTiming.length === 0) {
            this.router.navigate(['/checkout-new'])
            return
          }
          else {
            acceptanceTiming.forEach(element => {
              let from = moment(today.format('YYYY-MM-DD') + " " + element.from, "YYYY-MM-DD hh:mm A")
              let to = moment(today.format('YYYY-MM-DD') + " " + element.to, "YYYY-MM-DD hh:mm A")

              if (today >= from && today <= to) {
                timeAvailable = true
                return
              }
            });
          }
          if (timeAvailable === true) {
            this.router.navigate(['/checkout-new'])
          }
          else {
            if ("CONFIG.title === 'Nesma'") {
              this.router.navigate(['/cart-new'], { state: { shop: false } })
            }
            else {
              this.router.navigate(['/cart'], { state: { shop: false } })
            }

          }

        }
        else {
          if ("CONFIG.title === 'Nesma'") {
            this.router.navigate(['/cart-new'])
          }
          else {
            this.router.navigate(['/cart'])
          }

        }
      },
      err => {
        if ("CONFIG.title === 'Nesma'") {
          this.router.navigate(['/cart-new'])
        }
        else {
          this.router.navigate(['/cart'])
        }
      })
  }
  allCategories1: any;
  allCategories2: any;

  getAllCategories() {

    let reqData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": false,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 25
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "name",
          "sortorder": -1
        }
      }
    }
    this.appService.postApiCall(reqData, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
      resp => {
        if (config.title == 'Mayan') {
          this.allCategories = this.sortByKey(resp.result.data, 'name');
        }
        else if (config.title == 'Deera') {
          this.allCategories = this.sortByKey(resp.result.data, 'name');
          console.log(resp.result.data[0].subcategories, 'resp.result.data[0].subcategories')
          this.subcategory1 = resp.result.data[0].subcategories != null ? resp.result.data[0].subcategories : 0;
          this.subcategory2 = resp.result.data[1].subcategories != null ? resp.result.data[1].subcategories : 0;
          this.subcategory3 = resp.result.data[2].subcategories != null ? resp.result.data[2].subcategories : 0;
          this.subcategory4 = resp.result.data[3].subcategories != null ? resp.result.data[3].subcategories : 0;



        }
        else {
          if (this.category.includes('fruits')) {
            resp.result.data.filter(element => {
              if (element.name.toLowerCase() == 'fruits') {
                this.allCategories = element.subcategories
              }
            })
          }
          else if (this.category.includes('vegetable')) {
            resp.result.data.filter(element => {
              if (element.name.toLowerCase() == 'vegetables') {
                this.allCategories1 = element.subcategories
              }
            })
          }
          else if (this.category.includes('others')) {
            resp.result.data.filter(element => {
              if (element.name.toLowerCase() == 'others') {
                this.allCategories2 = element.subcategories
              }
            })
          }
          this.allCategories = resp.result.data;
          // this.allCategories.forEach((category) => {
          // console.log("category[0].image"+category[0].image)

          // this.categoryImage1 = category[0].image;
          // this.categoryImage2 = category[1].image;

          // })

          this.categoryImage1 = this.allCategories[0].image;
          this.categoryImage2 = this.allCategories[1].image;
          this.categoryName1 = this.allCategories[0].name;
          this.categoryName2 = this.allCategories[1].name;
        }







      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }

  removecartItem(index) {
    this.cartData.splice(index, 1);
    this.cartNewService.updateToCart(this.cartData);
  }
  removeallcartItem(length) {
    this.cartData.splice(0, length);
    this.cartNewService.updateToCart(this.cartData);
  }

  productListing(cate) {

    {
      document.getElementById("myfruits").style.width = "0";
      document.getElementById("myvegetables").style.width = "0";
      // document.getElementById("myothers").style.width = "0";
      document.getElementById("mySidenav").style.width = "0";
      document.getElementById("myfruits1").style.width = "0";
      document.getElementById("myvegetables1").style.width = "0";
      // document.getElementById("myothers1").style.width = "0";
      // document.getElementById("mySidenav1").style.width = "0";
      if (this.CONFIG.title == "Taazah") {
        document.getElementById('tab' + 1).classList.remove("active");
        document.getElementById('tab' + 2).classList.remove("active");
        document.getElementById('tab' + 3).classList.remove("active");
        document.getElementById('tab' + 4).classList.remove("active");
        document.getElementById('tab' + 5).classList.remove("active");
        document.getElementById('tab' + 6).classList.remove("active");
        document.getElementById('tab' + 7).classList.remove("active");
        document.getElementById('tab' + 8).classList.remove("active");
        document.getElementById('tab' + 9).classList.remove("active");
        document.getElementById('tab' + 10).classList.remove("active");

      }
      if (this.CONFIG.title == "Deera") {
        this.router.navigate([`/productListing`], { queryParams: { terms: [[], [cate], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []], type: 'combine' }, relativeTo: this.route, skipLocationChange: false });

      } else {
        this.router.navigate([`/productListing`], { queryParams: { terms: [[], [cate], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []], type: 'combine' }, relativeTo: this.route, skipLocationChange: false });

      }

    }


  }

  productListingDeera(cate, value) {
    console.log(value, "valuejjjjjjjjjjjjjjjjjjjjjjjjjj")
    this.productFilterService.updateAdminFormEvent({
      name: "categoryName",
      value: value

    })
    if (this.CONFIG.title == "Deera") {
      this.router.navigate([`/productListing`], { queryParams: { terms: [[], [cate], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []], type: 'combine' }, relativeTo: this.route, skipLocationChange: false });
    }

  }
  productListingSenbaga(category) {
    // if(category !== "Hair Care" && category !== "Eye Care"){
    this.router.navigate([`/productListing`], { queryParams: { terms: [[], [category], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []], type: 'combine' }, relativeTo: this.route, skipLocationChange: false });
  }
  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];

      if (typeof x == "string") {
        x = ("" + x).toLowerCase();
      }
      if (typeof y == "string") {
        y = ("" + y).toLowerCase();
      }

      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  }
  productsListSchift(category) {
    document.getElementById("mySidenav").style.width = "0";
    this.router.navigate([`/productListing`], { queryParams: { terms: [[], [category], [], [], [], [], [], [], [], [], [], [], []], type: 'combine' }, relativeTo: this.route, skipLocationChange: false });

  }

  contactUs() {
    this.router.navigate(['/contact-us'])
  }

  aboutUs() {
    this.router.navigate(['/about-us'])
  }

  toggleDropdown(open: boolean) {
    console.log("datayyyyyyyyyyyyy")
    this.dropdownOpen = open;
  }

  toggleSubmenu(open: boolean) {
    console.log("vvvvvvvvvvvv")
    this.submenuOpen = open;
  }

  toggleSubSubmenu(open: boolean) {
    console.log("ddddddddddd")
    this.subSubmenuOpen = open;
  }

}
